import React, {
  FC,
  PropsWithChildren,
  InputHTMLAttributes,
  BaseHTMLAttributes,
  useState,
  useCallback,
  useMemo,
} from 'react'
import { Field, FieldProps, useField } from 'formik'
import { styled, fonts, colors, mq } from '../../styles'
import showIcon from '../../images/show_s.png'
import hideIcon from '../../images/hide_s.png'
import { OptionTypeBase, StylesConfig } from 'react-select'
import AsyncSelect, { Props as AsyncSelectProps } from 'react-select/async'

export enum FieldWidth {
  Full = 'full',
  TwoThird = 'twoThird',
  Half = 'half',
  OneThird = 'oneThird',
}

interface FormFieldWrapperProps {
  width: FieldWidth
  label?: string
  error?: string
  hidden?: boolean
}

export const FormFieldWrapper: FC<PropsWithChildren<FormFieldWrapperProps>> = (
  props,
) => {
  return (
    <ItemContainer width={props.width} hidden={props.hidden}>
      {props.label && <LabelText>{props.label}</LabelText>}
      {props.children}
      <ErrorText>{props.error}</ErrorText>
    </ItemContainer>
  )
}

export const FormBigFieldWrapper: FC<PropsWithChildren<FormFieldWrapperProps>> =
  (props) => {
    return (
      <BigItemContainer width={props.width}>
        {props.label && <BigLabelText>{props.label}</BigLabelText>}
        {props.children}
        <BigErrorText>{props.error}</BigErrorText>
      </BigItemContainer>
    )
  }

const LabelText = styled.div({
  marginBottom: 5,
})

const ErrorText = styled.div({
  fontSize: 14,
  padding: '0 5px',
  minHeight: 17,
  color: '#da1d37',
})

const BigLabelText = styled.div({
  fontSize: '16px',
  marginBottom: 15,
})

const BigErrorText = styled.div((props) => {
  return {
    fontSize: 13,
    padding: '8px 5px 0',
    minHeight: 25,
    fontFamily: fonts.monosten.light,
    color: '#da1d37',
  }
})

interface FormikInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  width?: string
  allowShowErrors?: boolean
  passwordIconPaddingRight?: number
}

const FormikInput: FC<FormikInputProps> = ({ type = 'text', ...props }) => {
  const [localType, setLocalType] = useState(type)
  const handleToggleHide = useCallback(() => {
    if (localType === 'text') setLocalType('password')
    else setLocalType('text')
  }, [localType])
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => (
        <FormFieldWrapper
          label={props.label}
          error={
            meta.touched &&
            (props.allowShowErrors || props.allowShowErrors === undefined)
              ? meta.error
              : undefined
          }
          hidden={props.hidden}
          width={props.width ? (props.width as FieldWidth) : FieldWidth.Full}>
          <PasswordContainer>
            <Input type={localType} {...field} {...props} />
            {type === 'password' && !props.disabled && (
              <PasswordShowIcon
                src={localType === 'text' ? showIcon : hideIcon}
                onClick={handleToggleHide}
                alt={localType === 'text' ? 'show' : 'hide'}
              />
            )}
          </PasswordContainer>
        </FormFieldWrapper>
      )}
    </Field>
  )
}

const FormikBigInput: FC<FormikInputProps> = ({ type = 'text', ...props }) => {
  const [localType, setLocalType] = useState(type)
  const handleToggleHide = useCallback(() => {
    if (localType === 'text') setLocalType('password')
    else setLocalType('text')
  }, [localType])
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }: FieldProps) => (
        <FormBigFieldWrapper
          label={props.label}
          error={
            meta.touched &&
            (props.allowShowErrors || props.allowShowErrors === undefined)
              ? meta.error
              : undefined
          }
          width={props.width ? (props.width as FieldWidth) : FieldWidth.Full}>
          <BigPasswordContainer>
            <BigInput type={localType} {...field} {...props} />
            {type === 'password' && !props.disabled && (
              <BigPasswordShowIcon
                src={localType === 't ext' ? showIcon : hideIcon}
                onClick={handleToggleHide}
                alt={localType === 'text' ? 'show' : 'hide'}
                paddingRight={props.passwordIconPaddingRight}
              />
            )}
          </BigPasswordContainer>
        </FormBigFieldWrapper>
      )}
    </Field>
  )
}

const PasswordContainer = styled.div({
  display: 'flex',
})

const PasswordShowIcon = styled.img(
  {
    position: 'absolute',
  },
  mq({
    width: [40, 35],
    height: [40, 35],
    right: [20, 15],
    transform: [`translate(0, 17px)`, `translate(0, 17px)`],
  }),
)

const BigPasswordContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const BigPasswordShowIcon = styled.img<{ paddingRight?: number }>(
  {
    width: 40,
    height: 40,
    position: 'absolute',
    transform: `translate(0, 2px)`,
  },
  (props) => ({
    right: props.paddingRight ? props.paddingRight : 50,
  }),
)

interface FormTextProps extends BaseHTMLAttributes<HTMLDivElement> {
  text: string
  label?: string
  width?: string
}

const FormText: FC<FormTextProps> = (props) => {
  return (
    <FormFieldWrapper
      label={props.label}
      error={undefined}
      width={props.width ? (props.width as FieldWidth) : FieldWidth.Full}>
      <TextContainer>
        <TextDiv>{props.text}</TextDiv>
        {props.children}
      </TextContainer>
    </FormFieldWrapper>
  )
}

const TextContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  textTransform: 'capitalize',
})

const TextDiv = styled.div(
  {
    height: 36,
    padding: '8px 12px',
    width: '100%',
    fontFamily: fonts.monosten.light,
    color: 'black',
  },
  mq({
    fontSize: [13.4, 16],
  }),
)

type FlexBasisMap = {
  [key in FieldWidth]: string
}
const flexBasis: FlexBasisMap = {
  [FieldWidth.Full]: '100%',
  [FieldWidth.Half]: '50%',
  [FieldWidth.OneThird]: '33.33%',
  [FieldWidth.TwoThird]: '66.67%',
}

export const ItemContainer = styled.div<{
  width?: FieldWidth
  hidden?: boolean
}>(
  {
    flexGrow: 1,
  },
  (props) =>
    mq({
      marginBottom: props.hidden ? 0 : 3,
      flexBasis: [props.width ? flexBasis[props.width] : '100%', '100%'],
      padding: props.hidden ? 0 : ['0 8px', '0'],
    }),
)

export const BigItemContainer = styled.div<{
  width?: FieldWidth
}>(
  {
    flexGrow: 1,
  },
  (props) =>
    mq({
      flexBasis: [props.width ? flexBasis[props.width] : '100%', '100%'],
      padding: ['0 8px', '0'],
      marginBottom: [25, 0],
    }),
)

const Input = styled.input<{ addPaddingForNext?: boolean }>(
  ({ addPaddingForNext }) => {
    return mq({
      width: '100%',
      maxWidth: '100%',
      border: 'solid 1px black',
      borderRadius: '15px !important',
      padding: '20px 20px',
      margin: '0 auto',
      marginRight: addPaddingForNext ? [15, 0] : 'inherit',
      backgroundColor: '#EDE6D9',
      fontFamily: fonts.monosten.light,
      color: colors.goldenBrown,
      fontSize: ['24px', '32px', '21px'],
      '::-webkit-input-placeholder': {
        color: colors.mediumDarkBrown,
      },
      ':-webkit-autofill': {
        boxShadow: `0px 0px 0px 60px #EDE6D9 inset`,
      },
      ':-webkit-autofill::first-line': {
        fontSize: ['24px', '32px', '21px'],
      },
      ':last-child': {
        marginBottom: [0, 0, 0, 0],
      },
      ':focus': {
        borderRadius: '15px !important',
        outline: 'none',
        boxShadow: `2px 2px 0px grey`,
      },
    })
  },
)

const BigInput = styled.input(
  mq({
    backgroundColor: 'transparent',
    border: 'solid 1px black',
    borderRadius: [30, 20],
    height: [105, 60],
    padding: '8px 25px',
    width: '100%',
    fontFamily: fonts.monosten.light,
    color: 'black',
    fontSize: [32, 20],
    '::-webkit-input-placeholder': {
      color: colors.mediumDarkBrown,
    },
    ':-webkit-autofill': {
      boxShadow: `0px 0px 0px 160px #b57c40 inset`,
    },
    ':-webkit-autofill::first-line': {
      fontSize: [32, 20],
      fontFamily: fonts.monosten.light,
    },
    ':focus': {
      borderRadius: [30, 20],
      outline: 'none',
    },
    ':disabled': {
      backgroundColor: 'white',
      border: '1px black solid',
    },
  }),
)

export const FormContainer = styled.div(
  {
    backgroundColor: 'white',
    borderRadius: 30,
    border: 'solid 1px #a19d9a',
    maxWidth: 600,
  },
  mq({
    margin: ['auto', 'auto 5px'],
  }),
)

export const TransparentFormContainer = styled.div(
  {
    maxWidth: 770,
  },
  mq({
    margin: ['auto', 'auto 15px'],
  }),
)

export const FlexContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexBasis: '100%',
  flexFlow: 'row wrap',
})

export const ButtonContainer = styled.div<{ padding?: string }>(
  {
    display: 'flex',
    padding: '40px 0',
    maxWidth: 600,
    justifyContent: 'center',
  },
  (props) => ({
    padding: props.padding ? props.padding : '40px 0',
  }),
  mq({
    margin: ['auto', 'auto 20px'],
  }),
)

export const BigButton = styled.button({
  backgroundColor: colors.darkBrown,
  borderRadius: 30,
  border: 'none',
  color: 'white',
  fontFamily: fonts.topol,
  fontSize: 35,
  padding: '0 30px 2px',
  lineHeight: 1.5,
  cursor: 'pointer',
  WebkitFontSmoothing: 'antialiased',
  outline: 'none !important',
  textTransform: 'uppercase',
  ':disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
})

export const InfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 600,
  margin: 'auto',
  minHeight: 32,
  whiteSpace: 'pre-wrap',
})

const Info: FC<{ type?: 'error' | 'success'; message?: string }> = (props) => {
  return <InfoText type={props.type}>{props.message}</InfoText>
}

export const InfoText = styled.div<{ type?: 'error' | 'success' }>(
  {
    marginBottom: 10,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    fontFamily: fonts.monosten.light,
    WebkitFontSmoothing: 'auto',
    lineHeight: 1.5,
  },
  (props) => ({
    color: props.type === 'error' ? colors.warningRed : 'black',
  }),
  mq({
    fontSize: ['0.95em', '1em'],
  }),
)

export const Section = styled.div(
  {
    borderBottom: '1px solid #a19d9a',
    display: 'flex',
    flexDirection: 'column',
  },
  mq({
    padding: ['40px 30px', '24px 15px'],
  }),
)

export const SectionLast = styled.div(
  {
    display: 'flex',
    position: 'relative',
    height: 'auto',
  },
  mq({
    padding: ['40px 20px', '24px 15px'],
  }),
)

export const FormSection = styled.div({
  display: 'flex',
  position: 'relative',
  height: 'auto',
})

export const SectionTitle = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    flexBasis: '100%',
    fontFamily: fonts.topol,
    color: 'black',
    alignItems: 'center',
    position: 'relative',
    textAlign: 'center',
  },
  mq({
    padding: ['0px 12px 40px 12px', '0 0 25px'],
    fontSize: [24, '2.3em'],
  }),
)

export const SectionTitleNoBottomPadding = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    flexBasis: '100%',
    fontFamily: fonts.topol,
    color: 'black',
    alignItems: 'center',
    position: 'relative',
  },
  mq({
    padding: ['0px 12px 0px 12px', '0 0 5px'],
    fontSize: [24, '2.3em'],
  }),
)

interface FormikSelectProps {
  name: string
  label?: string
  width?: string
  allowShowErrors?: boolean
}

// For normal Select's props, use import {Props as SelectProps} from 'react-select'
const FormikAsyncSelect: FC<
  AsyncSelectProps<OptionTypeBase> & FormikSelectProps
> = ({ name, ...props }) => {
  const [field, meta, helpers] = useField({ name: name })
  const { setValue, setTouched, setError } = helpers

  const fieldValue = useMemo(() => {
    if (typeof field.value === 'string' && field.value !== '') {
      return { value: field.value, label: field.value }
    } else {
      return field.value
    }
  }, [field])

  const handleChange = useCallback(
    (value, action) => {
      setValue(value.value)
      setTouched(true)
      setError(undefined)
    },
    [setValue, setTouched, setError],
  )

  return (
    <FormFieldWrapper
      label={props.label}
      error={
        meta.touched &&
        (props.allowShowErrors || props.allowShowErrors === undefined)
          ? meta.error
          : undefined
      }
      width={props.width ? (props.width as FieldWidth) : FieldWidth.Full}>
      <AsyncSelect
        {...field}
        {...props}
        defaultOptions
        styles={props.styles ? props.styles : SelectStyle}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: colors.darkBrown,
            primary75: colors.goldenBrown,
            primary25: colors.lightBrown,
          },
        })}
        value={fieldValue}
        onChange={handleChange}
      />
    </FormFieldWrapper>
  )
}

const SelectStyle: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 10,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    backgroundColor: '#f0ece9',
    border: 'none',
    borderRadius: '10px 0 0 10px',
    height: 36,
    fontFamily: fonts.monosten.light,
    fontSize: 16,
    '@media (min-width: $575px)': {
      fontSize: 13.4,
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'black',
    '::-webkit-input-placeholder': {
      color: 'grey',
    },
    ':focus': {
      outline: 'none',
    },
    ':-webkit-autofill': {
      boxShadow: `0px 0px 0px 60px #b57c40 inset`,
      // WebkitBackgroundClip: 'text', // make it transparent
    },
    ':-webkit-autofill::first-line': {
      fontFamily: fonts.monosten.light,
      fontSize: 'inherit',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontFamily: fonts.monosten.light,
    fontSize: 16,
    '@media (min-width: $575px)': {
      fontSize: 13.4,
    },
  }),
}

const FormItems = {
  Input: FormikInput,
  BigInput: FormikBigInput,
  Button: BigButton,
  Info,
  AsyncSelect: FormikAsyncSelect,
  Text: FormText,
}

export default FormItems
