import { useState, useCallback, useEffect } from 'react'
import {
  getErrorData,
  ValidationError,
  getErrorStatusCode,
} from '@odainc/oda-api'
import { useAsyncOperation, AsyncOperationData } from './AsyncOperation'
import useInterval from './useInterval'

export interface ValidationErrorMap {
  [K: string]: string
}

export interface RequestData<T extends Array<any>, U>
  extends Omit<AsyncOperationData<T, U>, 'error'> {
  error: string | undefined
  validationErrors: ValidationErrorMap | undefined
}

export function useRequest<T extends Array<any>, U>(
  request: (...args: T) => Promise<U>,
): RequestData<T, U> {
  const operation = useAsyncOperation(request)

  const [error, setError] = useState<string | undefined>()
  const [validationErrors, setValidationErrors] = useState<ValidationErrorMap>()

  useEffect(() => {
    const processError = async () => {
      const { error, validationErrors } = await parseRequestError(
        operation.error,
      )
      setError(error)
      setValidationErrors(validationErrors)
    }
    processError()
  }, [operation.error])

  return {
    ...operation,
    error,
    validationErrors,
  }
}

export interface PaginatedRequestData<U>
  extends Omit<RequestData<[], U>, 'result'> {
  loadNextPage: () => void
  loadPreviousPage: () => void
  atBeginning: boolean
  atEnd: boolean
  page: number
  result: U[] | undefined
}

export function usePaginatedRequest<U extends any>(
  startPage: number,
  per: number,
  runRequest: (page: number, per: number) => Promise<U[]>,
): PaginatedRequestData<U> {
  const [page, setPage] = useState(startPage)
  const [hasReachedEnd, setHasReachedEnd] = useState(false)

  const request = useCallback(() => {
    return runRequest(page, per)
  }, [runRequest, page, per])

  const baseRequest = useRequest(request)

  const { result, reset: resetBaseRequest, run: runBaseRequest } = baseRequest

  // Re-run request if page or base request changes
  useEffect(() => {
    runBaseRequest()
  }, [page, runBaseRequest])

  // Set hasReachedEnd to true if we received fewer
  // items than we asked for (ie there are none remaining)
  useEffect(() => {
    if (result && result.length < per) {
      setHasReachedEnd(true)
    }
  }, [result, per])

  const reset = useCallback(() => {
    resetBaseRequest()
    setPage(startPage)
    setHasReachedEnd(false)
  }, [resetBaseRequest, startPage])

  const loadNextPage = useCallback(() => {
    setPage(page + 1)
  }, [page])

  const loadPreviousPage = useCallback(() => {
    setPage(page - 1)
    setHasReachedEnd(false)
  }, [page])

  return {
    ...baseRequest,
    loadNextPage,
    loadPreviousPage,
    reset,
    page,
    atBeginning: page === 0,
    atEnd: hasReachedEnd,
  }
}

export function usePaginatedRefreshRequest<U extends any>(
  startPage: number,
  per: number,
  runRequest: (page: number, per: number) => Promise<U[]>,
  refreshInterval: number,
): PaginatedRequestData<U> {
  const [page, setPage] = useState(startPage)
  const [hasReachedEnd, setHasReachedEnd] = useState(false)

  const request = useCallback(() => {
    return runRequest(page, per)
  }, [runRequest, page, per])

  const baseRequest = useRequest(request)

  const { result, reset: resetBaseRequest, run: runBaseRequest } = baseRequest

  useInterval(runBaseRequest, refreshInterval)

  // Re-run request if page or base request changes
  useEffect(() => {
    runBaseRequest()
  }, [page, runBaseRequest])

  // Set hasReachedEnd to true if we received fewer
  // items than we asked for (ie there are none remaining)
  useEffect(() => {
    if (result && result.length < per) {
      setHasReachedEnd(true)
    }
  }, [result, per])

  const reset = useCallback(() => {
    resetBaseRequest()
    setPage(startPage)
    setHasReachedEnd(false)
  }, [resetBaseRequest, startPage])

  const loadNextPage = useCallback(() => {
    setPage(page + 1)
  }, [page])

  const loadPreviousPage = useCallback(() => {
    setPage(page - 1)
    setHasReachedEnd(false)
  }, [page])

  return {
    ...baseRequest,
    loadNextPage,
    loadPreviousPage,
    reset,
    page,
    atBeginning: page === 0,
    atEnd: hasReachedEnd,
  }
}

const parseRequestError = async (e?: Error) => {
  let error: string | undefined
  let validationErrors: { [K: string]: string } | undefined
  if (e) {
    const status = getErrorStatusCode(e)
    if (status === -1) {
      error = 'Unable to connect to server'
    } else {
      const errorData = await getErrorData(e)
      error = errorData.message
      validationErrors = errorData.errors?.reduce((memo, v) => {
        memo[v.field] = getValidationErrorMessage(v)
        return memo
      }, {} as { [K: string]: string })
    }
  }
  return { error, validationErrors }
}

const getValidationErrorMessage = (error: ValidationError): string => {
  if (error.name === 'unique') {
    return `A record with this ${error.field} already exists`
  } else {
    return `invalid input (${error.name})`
  }
}
