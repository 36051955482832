import Oda, { Event } from '@odainc/oda-api'
import config from '../config'

export * from '@odainc/oda-api'

export interface GroupedEvents {
  today: Event[]
  upcoming: Event[]
  past: Event[]
}

export interface EventsByDay {
  [K: string]: Event[]
}

export interface EventsByMonth {
  [K: string]: Event[]
}

const oda = new Oda({
  basePath: config.apiRoot,
})

export default oda
