import React, { FC, useCallback } from 'react'
import { CreateAccountParams } from '@odainc/oda-api'
import { FormikProps } from 'formik'
import * as Yup from 'yup'
import { isMobile } from 'react-device-detect'

import FormikForm, { FormikFormProps, FormError } from './forms/FormikForm'
import FormItems, {
  TransparentFormContainer,
  FlexContainer,
  FormSection,
} from './forms/FormItems'
import { RowContainer, LinkButton } from './forms/CommonItems'
import { useRequest } from '../lib/ApiHooks'
import oda from '../lib/oda'
import { styled, spaces, mq } from '../styles'
import Button from '../components/Button'

export interface SignupData extends CreateAccountParams {
  type: string
}

const signupInitialValues: SignupData = {
  type: 'user',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  timezone: '',
  doNotDisturb: false,
}

export interface SignupPartialData {
  email: string
}

const signupValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(7, 'Must be 7 characters or more')
    .required('Required'),
})

interface SignupFormProps
  extends Omit<FormikFormProps<SignupData>, 'initialValues'> {
  initialValues: SignupPartialData
  onClickDoLogin?: () => void
}

const SignupForm: FC<SignupFormProps> = ({
  onClickDoLogin,
  initialValues,
  ...props
}) => {
  const createOdaAccount = useCallback((data: CreateAccountParams) => {
    try {
      return oda.createAccount(data)
    } catch (error) {
      return error
    }
  }, [])
  const signupRequest = useRequest(createOdaAccount)

  return (
    <>
      <FormikForm
        initialValues={{ ...signupInitialValues, ...initialValues }}
        validationSchema={signupValidationSchema}
        saveRequest={signupRequest}
        {...props}>
        {(props: FormError & FormikProps<SignupData>) => (
          <>
            <TransparentFormContainer>
              <FormSection>
                <FlexContainer>
                  <FormItems.Input
                    name="firstName"
                    placeholder="First name"
                    allowShowErrors={props.submitCount > 0}
                  />
                  <FormItems.Input
                    name="lastName"
                    placeholder="Last name"
                    allowShowErrors={props.submitCount > 0}
                  />
                  <FormItems.Input
                    name="email"
                    placeholder="Email"
                    allowShowErrors={props.submitCount > 0}
                  />
                  <FormItems.Input
                    name="password"
                    placeholder={
                      isMobile
                        ? 'Password (7 char min)'
                        : 'Password (7 characters min)'
                    }
                    type="password"
                  />
                </FlexContainer>
              </FormSection>
            </TransparentFormContainer>
            <ButtonContainer padding={'30px 0 40px'}>
              <SubmitButton type="submit" size={'medium'}>
                {props.isSubmitting ? 'Signing up...' : 'Sign up now'}
              </SubmitButton>
            </ButtonContainer>
          </>
        )}
      </FormikForm>

      {onClickDoLogin && (
        <RowContainer>
          {'I already have an Oda account. '}
          <LinkButton type="button" onClick={onClickDoLogin}>
            Login
          </LinkButton>
        </RowContainer>
      )}
    </>
  )
}

const ButtonContainer = styled.div<{ padding?: string }>(
  {
    display: 'flex',
    maxWidth: 600,
    justifyContent: 'center',
  },
  mq({
    marginTop: spaces.small,
  }),
)

const SubmitButton = styled(Button)(mq({}))

export default SignupForm
