import { styled, fonts, mq, colors, spaces, typography } from '../../styles'

export const Container = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#F5EEE8',
    paddingBottom: 50,
    paddingTop: 60,
  },
  mq({
    paddingLeft: [0, 10],
    paddingRight: [0, 10],
  }),
)

export const Title = styled.div(
  {
    textAlign: 'center',
  },
  mq({
    paddingBottom: [80, 40],
    paddingTop: spaces.medium,
  }),
  typography.largeSerif,
)

export const FlexContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  flexBasis: '100%',
  flexFlow: 'row wrap',
})

export const LinkButton = styled.button({
  textDecoration: 'underline',
  fontFamily: fonts.topol,
  fontSize: '1em',
  padding: 0,
})

export const BottomLinks = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: '1.2em',
  textAlign: 'center',
})

export const RowContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  whiteSpace: 'pre-wrap',
})

export const InfoContainer = styled.div<{ maxWidth?: number }>(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    alignItems: 'center',
    minHeight: 32,
    textAlign: 'center',
    fontFamily: fonts.monosten.light,
    WebkitFontSmoothing: 'auto',
    lineHeight: 1.5,
    whiteSpace: 'pre-wrap',
  },
  mq({
    fontSize: ['0.95em', '1em'],
    padding: [0, '0 20px 0 20px'],
  }),
  (props) => ({
    maxWidth: props.maxWidth ? props.maxWidth : 600,
  }),
)

export const EditButton = styled.button({
  position: 'absolute',
  right: 0,
  color: colors.goldenBrown,
  textDecoration: 'underline',
  fontFamily: fonts.monosten.light,
  fontSize: 14,
})

export const BigButton = styled.button(
  {
    borderRadius: 30,
    border: `2px solid ${colors.darkBrown}`,
    color: colors.darkBrown,
    fontFamily: fonts.topol,
    fontSize: 25,
    width: '100%',
    lineHeight: 1.5,
    cursor: 'pointer',
    WebkitFontSmoothing: 'antialiased',
    outline: 'none !important',
    ':disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    textTransform: 'uppercase',
  },
  mq({
    margin: ['0 20px', '0 10px'],
  }),
)

export const MediumButton = styled.button(
  {
    borderRadius: 30,
    border: `2px solid ${colors.darkBrown}`,
    color: colors.darkBrown,
    fontFamily: fonts.topol,
    fontSize: 25,
    lineHeight: 1.5,
    cursor: 'pointer',
    WebkitFontSmoothing: 'antialiased',
    outline: 'none !important',
    ':disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    textTransform: 'uppercase',
    padding: '0 30px',
  },
  mq({
    margin: ['0 20px', '0 10px'],
  }),
)
