import React, { useEffect, ReactNode, useCallback } from 'react'
import { Form, Formik, FormikProps, FormikConfig, FormikHelpers } from 'formik'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { RequestData, ValidationErrorMap } from '../../lib/ApiHooks'
import { AsyncState } from '../../lib/AsyncOperation'
import { InfoContainer, InfoText } from './FormItems'

export interface FormError {
  requestErrors: ValidationErrorMap | undefined
}

export interface FormikFormProps<T>
  extends Omit<FormikConfig<T>, 'children' | 'onSubmit'> {
  saveRequest?: RequestData<any, any>
  handleErrorsDisplayExternally?: boolean
  children?: (props: FormError & FormikProps<T>) => ReactNode
  onComplete?: (state: AsyncState) => void
  onError?: (error?: string) => void
  onSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>
}

// Can't remove UpdateType ?_?
const FormikForm = <InputType, UpdateType>({
  saveRequest,
  handleErrorsDisplayExternally,
  children,
  onComplete,
  onError,
  onSubmit,
  ...props
}: FormikFormProps<InputType>) => {
  const formikRef = React.useRef<FormikProps<InputType>>(null)
  const isMounted = React.useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  // Intercept to run request, if there's one,
  // also set submitting state to false
  const handleSubmit = useCallback(
    async (values: InputType, formikHelpers: FormikHelpers<InputType>) => {
      onSubmit && (await onSubmit(values, formikHelpers))
      if (saveRequest) {
        await saveRequest.run(values)
      }

      // Check if the form is still mounted,
      // before setting the isSubmitting state
      if (isMounted.current) {
        formikHelpers.setSubmitting(false)
      }
    },
    [saveRequest, onSubmit, isMounted],
  )

  const saveRequestState = saveRequest?.state
  const saveRequestError = saveRequest?.error
  // Reset form after complete
  useEffect(() => {
    if (saveRequestState) {
      formikRef.current?.setTouched({})
      if (saveRequestState === AsyncState.Complete) {
        formikRef.current?.resetForm()
        onComplete && onComplete(saveRequestState)
      } else if (saveRequestState === AsyncState.Failed) {
        onError && onError(saveRequestError)
      }
    }
  }, [saveRequestState, saveRequestError])

  // Update form values if initialValues updated
  useDeepCompareEffect(() => {
    formikRef.current?.setValues(props.initialValues)
  }, [props.initialValues])

  return (
    <Formik innerRef={formikRef} onSubmit={handleSubmit} {...props}>
      {(innerProps: FormikProps<InputType>) => (
        <Form>
          {!handleErrorsDisplayExternally && saveRequest && saveRequest.error && (
            //!saveRequest.validationErrors &&
            <InfoContainer>
              <InfoText type="error">{saveRequest.error}</InfoText>
            </InfoContainer>
          )}
          {children &&
            children({
              ...innerProps,
              requestErrors: saveRequest?.validationErrors,
            })}
        </Form>
      )}
    </Formik>
  )
}

export default FormikForm
