import React, { FC, useState, useCallback, useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
import { FormikHelpers } from 'formik'
import { CreateSessionParams } from '@odainc/oda-api'
import Cookies from 'js-cookie'

import Layout from '../components/Layout'
import '../components/base.css'
import { RedeemSignupQuery } from '../../graphql-types'
import { withPreview } from 'gatsby-source-prismic'
import extractGlobals from '../lib/extractGlobals'
import SignupForm, { SignupData } from '../components/SignupForm'
import { SectionTitle } from '../components/FrontMatter'
import { mq, spaces, styled, typography, colors } from '../styles'
import Logo from '../images/logo.png'
import { LinkButton } from '../components/Button'
import { subscribeMember, SubscribeState } from '../lib/SubscribeUtils'
import {
  recordReferralSignupFinish,
  recordReferralSignupFail,
} from '../lib/Analytics'
import oda from '../lib/oda'

const RedeemSignup: FC<PageProps<RedeemSignupQuery>> = ({ location, data }) => {
  const { meta, footer } = extractGlobals(data)

  const [error, setError] = useState<string | undefined>()
  const [info, setInfo] = useState<string | undefined>()
  const [signupData, setSignupData] = useState<SignupData>()

  const [code, email, newsletter, initialValues] = useMemo(() => {
    if (location.state) {
      const state = location.state as any
      return [
        state.code,
        state.email,
        state.newsletter,
        { email: state.email, referralCode: state.code },
      ]
    } else {
      return [
        undefined,
        undefined,
        undefined,
        { email: undefined, referralCode: undefined },
      ]
    }
  }, [location.state])

  const handleSignup = useCallback(
    (values: SignupData, formikHelpers: FormikHelpers<SignupData>) => {
      // const { confirmPassword, ...data } = values
      setSignupData(values)
    },
    [code],
  )

  const handleSignupComplete = useCallback(async () => {
    if (signupData) {
      recordReferralSignupFinish(signupData.email, code)

      setError(undefined)
      setInfo('success')

      // Do login & set credentials to cookie
      try {
        const credentials = await oda.createSession(
          signupData as CreateSessionParams,
        )
        oda.setCredentials(credentials.accessToken, credentials.refreshToken)
        Cookies.set('accessToken', credentials.accessToken, {
          domain: '.oda.co',
          expires: 7,
        })
        Cookies.set('refreshToken', credentials.refreshToken, {
          domain: '.oda.co',
          expires: 7,
        })
      } catch (e) {
        console.log(e)
      }

      if (newsletter) {
        const result = await subscribeMember(
          signupData.email,
          `${signupData.firstName} ${signupData.lastName}`,
          code,
        )

        if (result.state !== SubscribeState.Complete) {
          // fail sliently
        } else {
          // sign up sliently
        }
      } else {
        // does not sign up newsletter
      }
    }
  }, [signupData, code, newsletter])

  const handleSignupError = useCallback(
    (error?: string) => {
      if (error) {
        recordReferralSignupFail(email, error, code)
      }

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      if (error) setError(error)
      setInfo(undefined)
    },
    [email, code],
  )

  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      {info ? (
        <Section>
          <Title>Welcome To Oda</Title>

          <HeroLogoImage src={Logo} />

          <Subtitle>
            You’re all set! You can now access Oda programming and listen live
            at <a href="https://members.oda.co">members.oda.co</a>.
          </Subtitle>

          <SectionContent>
            <ListenButton
              colorScheme="dark"
              size={'medium'}
              href={'https://members.oda.co'}>
              LISTEN NOW
            </ListenButton>
          </SectionContent>

          <Subtitle>
            <br />
            <br />
            Don’t forget to set a reminder for Beverly Glenn&#8209;Copeland’s
            performances:
            <br />
            Wednesday, August 4th at 7pm EDT
            <br />
            Thursday, August 5th at 4pm EDT
            <br />
            <br />
            We’ll send you a newsletter with our performance schedule every
            week, starting this Monday (sign up for the newsletter if you didn’t
            already!).
            <br />
            <br />
          </Subtitle>
        </Section>
      ) : (
        <Section>
          <Title>Finish Setting Up Your Account</Title>

          <Subtitle>
            You’re almost ready to listen! Just fill out the form below to
            finish setting up your account
          </Subtitle>

          <SectionContent>
            <SignupForm
              initialValues={initialValues}
              onSubmit={handleSignup}
              onComplete={handleSignupComplete}
              onError={handleSignupError}
            />
          </SectionContent>
        </Section>
      )}
    </Layout>
  )
}

export default withPreview(RedeemSignup)

const Title = styled(SectionTitle)(mq({}))

const HeroLogoImage = styled.img(
  mq({
    width: [90, 60, 60],
    height: [90, 60, 60],
    margin: '0 auto',
    marginTop: spaces.small.map((n) => -n / 2),
    marginBottom: spaces.medium,
    display: 'block',
  }),
)

const Subtitle = styled.div(
  typography.smallSerif,
  mq({
    textAlign: 'center',
    marginBottom: spaces.small,
    whiteSpace: 'pre-line',
    padding: '0 20px',
    maxWidth: 600,
    margin: '0 auto',
  }),
)

const Section = styled.div(
  mq({
    paddingTop: spaces.large,
    paddingBottom: spaces.large,
    maxWidth: 800,
    margin: 'auto',
  }),
)

const SectionContent = styled.div(
  mq({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    margin: '0 auto',
  }),
)

const BirdImage = styled.img(
  mq({
    objectFit: 'cover',
    width: [400, '100%'],
    height: ['auto', '100%'],
    margin: 'auto',
    marginTop: spaces.small,
    marginBottom: spaces.medium,
    padding: [0, '0 20px'],
  }),
)

const ListenButton = styled(LinkButton)(
  mq({
    marginTop: spaces.small,
  }),
)

export const pageQuery = graphql`
  query RedeemSignup {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
  }
`
