import { useState, useCallback } from 'react'

export enum AsyncState {
  Initial = 'initial',
  Started = 'started',
  Complete = 'complete',
  Failed = 'failed',
}

export interface AsyncOperationData<T extends Array<any>, U> {
  run: (...args: T) => Promise<void>
  reset: () => void
  result: U | undefined
  error: Error | undefined
  state: AsyncState
}

export function useAsyncOperation<T extends Array<any>, U>(
  operation: (...args: T) => Promise<U>,
): AsyncOperationData<T, U> {
  const [result, setResult] = useState<U | undefined>()
  const [error, setError] = useState<Error | undefined>()
  const [state, setState] = useState(AsyncState.Initial)

  const run = useCallback(
    async (...args: T) => {
      setState(AsyncState.Started)
      try {
        const res = await operation(...args)
        setResult(res)
        setState(AsyncState.Complete)
      } catch (e) {
        setError(e)
        setState(AsyncState.Failed)
      }
    },
    [operation],
  )

  const reset = useCallback(() => {
    setError(undefined)
    setState(AsyncState.Initial)
    setResult(undefined)
  }, [])

  return {
    run,
    reset,
    result,
    error,
    state,
  }
}
